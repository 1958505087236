body {
  padding-top: 5rem;
}
.starter-template {
  padding: 3rem 1.5rem;
  text-align: center;
}

.navbar {
    background-color: #F8F8F8;
    border-color: #E7E7E7;
}

.nav-link {
	color:black;
}

.navbar-fixed-top .navbar-brand {
    padding: 0 15px;
}

@media(min-width:768px) {
    body {
        padding-top: 100px; /* Required padding for .navbar-fixed-top. Change if height of navigation changes. */
    }

    .navbar-fixed-top .navbar-brand {
        padding: 10px 0;
    }
}

.fa-battery-full{
  position: relative;
  margin:10px;
  top:2px;
}
.fa-battery-half{
  position: relative;
  margin:10px;
  top: 2px;
}

.fa-battery-empty{
  position: relative;
  margin:10px;
  top: 2px;
}

.label{
  font-size:100%;
}

.btn-group-sm>.btn, .btn-sm{
  font-size:0.8rem;
}

.btn{
  vertical-align: top;
}