.Summary {
    width: 100%;
}
.summaryOuterContainer {
    width: 100vw;
    height: 100vh;
    margin: 0 auto;
}
.summaryPageWrap {
    width: 80vw;
    height: 100vh;
    margin-left: 85px !important;
}
