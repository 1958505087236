.led-box {
  height: 30px;
  width: 33%;
  margin: 10px 0;
  float: left;
}
.led-box p {
  font-size: 12px;
  text-align: center;
  margin: 1em;
}
.led-red {
  margin: 0 auto;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #441313 0 -1px 9px, rgba(255, 0, 0, 0.5) 0 2px 12px;
  -webkit-animation: blinkRed 0.5s infinite;
  -moz-animation: blinkRed 0.5s infinite;
  -ms-animation: blinkRed 0.5s infinite;
  -o-animation: blinkRed 0.5s infinite;
  animation: blinkRed 0.5s infinite;
}
.led-yellow {
  margin: 0 auto;
  width: 24px;
  height: 24px;
  /*background-color: #FF0; */
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #808002 0 -1px 9px, #ff0 0 2px 12px;
  -webkit-animation: blinkYellow 1s infinite;
  -moz-animation: blinkYellow 1s infinite;
  -ms-animation: blinkYellow 1s infinite;
  -o-animation: blinkYellow 1s infinite;
  animation: blinkYellow 1s infinite;
}
.led-green {
  margin: 0 auto;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #304701 0 -1px 9px, #89ff00 0 2px 12px;
}
.led-blue {
  margin: 0 auto;
  width: 24px;
  height: 24px;
  background-color: #24e0ff;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #006 0 -1px 9px, #3f8cff 0 2px 14px;
}
.led-orange {
  margin: 0 auto;
  width: 24px;
  height: 24px;
  background-color: #ff7f00;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #006 0 -1px 9px, #ff7f00 0 2px 14px;
}
