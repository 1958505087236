@use '../../../_base.scss';

.block {
    text-align: center;
    margin: auto;
    justify-content: center;
    align-items: center;
    font-size: 6vh;
    color: base.$theme-blue;
}
