@use '../../_base.scss';


.wifiFormContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50% !important;
    background-color: base.$bg-color;
    border: 2px solid #f1f1f1;
    border-radius: 25px;
    box-shadow: -1px 1px 10px 2px #5e5e5e;
    margin-top: 24px;

    .wifiForm {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 24px;
    }
}

@media all and (max-height: 800px) {
    .wifiFormContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 50px;
    }
}
@media all and (max-height: 700px) {
    .wifiFormContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 25px;
    }
}
@media all and (max-height: 600px) {
    .wifiFormContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 5px;
    }
}
