
.deviceView-top-row {
	height: 33%;
	width: 80%;
	justify-content: center;
	align-items: center;
	padding-top: 10px;
	margin: 0px !important;
}
.deviceView-middle-row {
	height: 33%;
	width: 80%;
	justify-content: center;
	align-items: center;
	margin: 0px !important;
}
.deviceView-bottom-row {
	height: 33%;
	width: 80%;
	justify-content: center;
	align-items: center;
	margin: 0px !important;
}


@media screen and (max-width: 650px) {
    .deviceView-top-row, .deviceView-middle-row, .deviceView-bottom-row{
        width: 95%;
    }
}
@media all and (max-width: 415px) {
    .deviceView-top-row, .deviceView-middle-row, .deviceView-bottom-row{
        height: 60%;
    }
}
@media all and (min-width: 568px) and (max-width: 737px) {
    .deviceView-top-row, .deviceView-middle-row, .deviceView-bottom-row{
        height: 75%;
    }
}