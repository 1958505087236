@use '../../../_base.scss';

.blocksLabel {
    margin-top: 10px;
    margin-left: 5px;
    // color: base.$theme-ligt-blue;
    // color: #84878c;
    color: base.$theme-black;
    font-size: 2vh;
}

.dropdownlabel {
    font-size: small;
}

.BlockBackground {
    background-color: base.$bg-color;
    border-radius: 25px;
	box-shadow: base.$block-box-shadow;
}
