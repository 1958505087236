.topicCard {
    height: 100px;
    margin: 20px;
    background-color: #fefefe;
    border-radius: 10px;
    box-shadow: 0px 1px 4px 0px rgb(0, 0, 0, 0.5);
    cursor: pointer;
}


@media screen and (max-width: 1200px) {
    .topicCard {
        height: 160px;
        margin: 20px;
        background-color: #fefefe;
        border-radius: 10px;
        box-shadow: 0px 1px 4px 0px rgb(0, 0, 0, 0.5);
        cursor: pointer;
    }
}

.topicRow1 {
    font-size: 28px;
    text-align: left;
    color: #3e3e3e;
    height: 40%;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
}

.topicRow2 {
    font-size: 14px;
    text-align: left;
    color: #7A8490;
    height: 60%;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
}

