@use '../../../_base.scss';

.dashboardGraphBackground {
    // background-color: base.$bg-color;
    // border-radius: 25px;
	// box-shadow: base.$block-box-shadow;
    // width: 100%;
    height: 100%;
    overflow: auto;
}

.waterUseGraph {
    height: 90%;
    padding-top: 2%;
    padding-bottom: 2%;
    padding-left: 2%;
    padding-right: 2%;
    // overflow: auto;
    // max-height: 100%;
    // width: 100%;
}

.expandedWaterUseGraph {
    height: 500px;
    width: 95vw;
}

.modal-100w {
    width: 100vw;
    max-width: 100vw !important;
}