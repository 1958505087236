@use '../../../_base.scss';

.alertHistoryGraphBackground {
    background-color: base.$bg-color;
    border-radius: 25px;
	box-shadow: base.$block-box-shadow;
    width: 100%;
    height: 100%;
}

.alertHistoryGraphLabel {
    margin-top: 10px;
    margin-left: 15px;
    color: base.$theme-ligt-blue;
    font-size: 2vh;
}

.alertHistoryGraph {
    height: 100%;
    padding: 15px;
    justify-content: center;
    align-items: center;
}

.noAlertsLabel {
    color: #56b947;
    font-size: 5vh;
}
