@use '../../_base.scss';

.masqueradeOuterContainer {
    width: 100vw;
    height: 100vh;
    margin: 0 auto;
}
.masqueradePageWrap {
    height: calc(100vh - 70px);
    width: calc(100vw - 70px);
    display: flex;
    align-items: center;
    justify-content: center;
}
.masqueradeFormContainer {
    height: 50vh;
    width: 50vw;
    background-color: base.$bg-color;
    border: 2px solid #f1f1f1;
    border-radius: 25px;
	box-shadow: base.$block-box-shadow;
}

.select-title {
    padding-bottom: 10px;
}

@media all and (max-width: 825px) {
    .masqueradeFormContainer {
        width: 70%;
    }
    .select-title {
        text-align: center;
        font-size: 25px;
    }
    #userSelect {
        width: 60% !important;
    }
    .masqueradePageWrap {
        align-items: flex-start;
        margin: 70px 0px 0px 35px !important;
    }
}

.masqueradeForm {
    height: 50vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
