@use '../../_base.scss';

// .dashboardOuterContainer {
//     width: 100vw;
//     height: 100vh;
//     margin: 0 auto;
// }

// .dashboardPageWrap {
//     height: calc(100vh - 70px);
//     width: calc(100vw - 70px);
//     margin: 0px 0px 0px 0px !important;
// }

// .scrollContainer {
//     // height: calc(100vh - 70px);
//     // width: 100vw;
//     margin: 0px 0px 0px 0px !important;
//     justify-content: center;
//     align-items: center;
//     overflow: auto;
// }

// @media screen and (max-width: 769) {
//     .scrollContainer {
//         height: calc(100vh - 140px);
//         width: 100vw;
//         margin: 0px 0px 0px 0px !important;
//         justify-content: center;
//         align-items: center;
//         overflow: auto;
//     }
// }

// .dashboardTopBar {
//     height: 70px;
//     width: 100vw;
//     background-color: base.$bg-color;
//     vertical-align: middle;
// }

// .logoClass {
//     position: absolute;
//     margin-left: 100px;
//     padding-top: 20px;
//     width: 150px;
//     height: 50px;
// } 

// .dashboardTimeButtons {
//     float: right;
//     margin-right: 30px;
//     padding-top: 20px;
// }

.dashboard-row {
    height: 50%;
    width: calc(100vw - 100px);
    // width: 80%;
    justify-content: center;
    align-items: center;
    margin: 0px !important;
}

.dashboardBlock {
    display: flex;
    height: 95%;
}

.testBlock {
    display: flex;
    height: 100%;
    padding-bottom: 5px;
    
}

@media screen and (max-width: 767px) {

    .dashboard-row {
        height: auto;
    }

    .dashboardBlock {
        height: 100%;
        padding-top: 20px;
    }

}

@media screen and (max-width: 650px) {

    .dashboard-row {
        width: 100vw;
    }
}

.BlockBackground {
    background-color: base.$bg-color;
    border-radius: 25px !important;
	box-shadow: base.$block-box-shadow;
    width: 100%;
    overflow: hidden !important;
}

.BlockBackgroundFlex {
    background-color: base.$bg-color;
    border-radius: 25px !important;
	box-shadow: base.$block-box-shadow;
    width: 100%;
    overflow: hidden !important;
    display: flex;
    flex-direction:column;
}