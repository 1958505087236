@use '../../_base.scss';

.alertBlock {
	width: calc(100vw - 140px);
	// height: 90%;
	margin-top: 20px;
	margin-right: 20px;
	margin-bottom: 20px;
    background-color: base.$bg-color;
	border-radius: 25px;
	box-shadow: base.$block-box-shadow;
	cursor: pointer;
}
.alertHistoryBlock {
	width: calc(100vw - 140px);
	// height: 90%;
	margin-top: 20px;
	margin-right: 20px;
	margin-bottom: 20px;
    background-color: base.$bg-color;
	border-radius: 25px;
	box-shadow: base.$block-box-shadow;
}
.alertBlockIcon {
	display: flex;
	justify-content: center;
	align-items: center;
	padding-top: 20px;
	padding-bottom: 20px;
}
.alertTable {
	display: flex;
	justify-content: center;
	align-items: center;
}
.alertBlockRow2 {
	font-size: 28px;
	text-align: left;
	color: #3e3e3e;
}
.alertBlockRow1 {
	font-size: 14px;
	text-align: left;
	color: #7A8490;
}
.alertHistoryRow {
	justify-content: center;
	align-items: center;
	float: right;
	padding-right: 15px;
	padding-bottom: 10px;
	margin-top: -8px;
	&:hover {
		>.alertHistoryButton {
			color: base.$theme-blue;
			text-decoration: none;
		}
		>.svg {
			fill: base.$theme-blue;
		}
	}
}
.alertHistoryButton {
	color: #7A8490;
	float: right;
	padding-right: 5px;
}

@media all and (max-width: 815px) {
    .alertBlockRow2 {
        font-size: 18px;
    }
}
