.setupCardBlock {
    width: 40% !important;
    height: 100%;
    max-width: 40% !important;
    /* margin-left: 90px !important; */
    padding-top: 10px;
    margin: auto;
    background-color: #fefefe;
    border-radius: 25px;
    box-shadow: 0px 1px 4px 0px rgb(0, 0, 0, 0.5);
    position: relative;
}
 
.dot {
    height: 15px;
    width: 15px;
    margin: 0 2px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    transition: background-color 0.6s ease;
  }
  
  .activeDot {
    background-color: #717171;
  }
  
  .nameForm {
    width: 100%;
    display: flex;
    padding-top: 10px;
    padding-bottom: 10px;
    flex-direction: column;
    align-items: center;
}

.extForm {
    width:120px;
    min-width: 100px;
    display: flex;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 30px;
    padding-right:10px;
    flex-direction: column;
    align-items: center;
}

.phoneForm {
    width: 50%;
    display: flex;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right:30px;
    flex-direction: column;
    align-items: right;
    flex-grow: 2;
    float: right;
}

.alertTable {
    display: flex;
    justify-content: center;
    align-items: center;
}

.alertBlockRow2 {
    font-size: 28px;
    text-align: left;
    color: #3e3e3e;
}

.alertBlockRow1 {
    font-size: 14px;
    text-align: left;
    color: #7A8490;
}

.cardButtonRow {
    position: absolute;
    bottom: 10%;
    left: 50%; /* position the left edge of the element at the middle of the parent */
    width: 80%;
    transform: translate(-50%, -50%);
    text-align: center;
}

.cardPrevButton {
    position: relative;
    float: left;
}

.cardNextButton {
    position: relative;
    float: right;
}

.dotRow {
    position: absolute;
    bottom: 15px;
    left: 50%; /* position the left edge of the element at the middle of the parent */

    transform: translate(-50%, -50%);
    text-align: center;
}

h1 {
    text-align: center !important;
    width: 100%;
    padding-top: 15px;
    font-size: 2rem !important;
}

/* p:after
{
    width: 45%;
    text-align: center;
    content:' ';
    display:block;
    margin:auto;
    border-bottom :1px dotted #d0d0d0;
} */

@media screen and (max-width: 768px) {

    .setupCardBlock {
        width: 40% !important;
        height: 90%;
        max-width: 95vw !important;
        /* margin-left: 90px !important; */
        margin: auto;
        background-color: #fefefe;
        border-radius: 25px;
        box-shadow: 0px 1px 4px 0px rgb(0, 0, 0, 0.5);
    }

    .nameForm {
        width: 100%;
        display: flex;
        padding-top: 10px;
        flex-direction: column;
        align-items: center;
    }

    .extForm {
        width:80px;
        min-width: 50px;
        display: flex;
        padding-top: 10px;
        padding-left: 15px;
        flex-direction: column;
        align-items: center;
    }
    
    
    .phoneForm {
        width: 50%;
        display: flex;
        padding-top: 10px;
        padding-left:5px;
        padding-right:15px;
        flex-direction: column;
        align-items: right;
        flex-grow: 2;
        float: right;
    }
}