@use '../../_base.scss';


.boxBackground {
    background-color: base.$bg-color;
    border-radius: 25px;
	box-shadow: base.$block-box-shadow;
    padding: 24px;
    margin: 24px;
    justify-content: center;
    align-items: center;
    text-align: center;
    overflow: auto;
    height: 100%;
    width: 100%;
}

.calcRow {
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 24px;
    width: 100%;
}

.calcLogo {
    width: 100%;
    height: 70px;
    display: flex;
    justify-content: center;
    position: absolute;
    z-index: 99;
}

.calcLogoClass {
    position: absolute;
    padding-top: 20px;
    width: 200px;
    height: 70px;
}

.calcText {
    white-space: pre-wrap;
    text-align: center;
    width: 100%;
    height: auto;
}