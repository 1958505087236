$bg-color: #fefefe;
$theme-white: #f8f9f8;
$theme-ligt-blue: #babfc9;
$theme-black: #212529;
$theme-blue: #3578bd;
$theme-red: #f04451;
$theme-green: #4cc0ac;

$block-box-shadow: 0px 1px 4px 0px rgb(0, 0, 0, 0.5);

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}
