@use '../../../_base.scss';

.repairAlerts {
    text-align: center;
    margin: auto;
    justify-content: center;
    align-items: center;
    font-size: 6vh;
    color: base.$theme-blue;
}

.repairProgress {
    background-color: rgba(53, 121, 189, 0.5);
    height: 100%;
    margin-bottom: 25%;
    display: inline-block;
    border: 1px solid #3579bd;
    border-radius: 25px;
}

.repairWorkingProgress {
    background-color: base.$theme-ligt-blue;
    height: 100%;
    display: inline-block;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
    border-color: rgba(86, 185, 71, 1);
    border-style: solid;
    border-width: 2px 2px 2px 0;
}
