.repairViewOuterContainer {
    width: 100vw;
    height: 100vh;
    margin: 0 auto;

    .repairViewPageWrap {
        height: calc(100vh - 70px);
        width: calc(100vw - 70px);
        margin: 20px 0px 0px 100px !important;
        padding-bottom: 20px;
        overflow: scroll;
    }
    @media all and (max-width: 415px) {
        .repairViewPageWrap {
            margin: 0 0px 0px 70px !important;
        }
    }
}
