@use '../../../_base.scss';

.deviceBlockBackground {
    background-color: base.$bg-color;
    border-radius: 25px;
    box-shadow: base.$block-box-shadow;
    height: 90%;
    width: 90%;
    margin-left: 70px !important;
    /* padding-top: 20px; */
}

.deviceViewLabel {
    margin-top: 10px;
    padding-left: 30px;
    color: base.$theme-black;
    font-size: 2vh;
}

.deviceViewExportBtn {
    padding-right: 30px;
    color: base.$theme-ligt-blue;
    font-size: 2vh;
}
.doughnutChart {
    height: 80%;
}
.labelCol {
    height: 70%;
    overflow: scroll;
}
@media all and (max-width: 825px) {
    .deviceBlockBackground {
        margin-left: 10px !important;
    }
}
@media all and (max-width: 415px) {
    .doughnutChart {
        height: 50px !important;
        margin-bottom: 20px !important;
    }
}
@media all and (min-width: 568px) and (max-width: 737px) {
    .doughnutChart {
        height: 50px !important;
    }
    .labelCol {
        height: 60%;
    }
}
