@use '../../_base.scss';

.changePasswordOuterContainer {
    height: 100%;
    width: 75%;
}

.changePasswordFormContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 25vh;
}

.changePasswordForm-2 {
    width: 75%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.changePasswordInput {
    background-color: #f8f9f8 !important;
    width: 100%;
}

.changePasswordButton {
    font-size: 21px;
    color: #ffffff;
    border: 0;
    background-color: #3578bd !important;
    border-color: #3578bd !important;
    border-radius: 3px;
    transition: all 250ms ease-in-out;
    width: 75%;
}

.settings-logout {
    width: 20%;
    // margin-left: 30px;
    margin-top: auto;
    color: rgba(231, 60, 63, 1);
    font-size: 1.75em;
}

@media screen and (max-width: 650px) {
}