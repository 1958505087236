.container2 {
    position: relative;


    
    height:220px;
    width:225px;
    text-align: center;
    text-align: center;
    margin-left:5px;
    margin-right:5px;
    display:inline-block;
    font-size:20px;
    font-weight:bold;
}


table.blueTable {
    border: 1px solid #FFFFFF;
    background-color: #FFFFFF;
    width: 100%;
    text-align: left;
  }
  table.blueTable td, table.blueTable th {
    border: 1px solid #FFFFFF;
    padding: 3px 2px;
  }
  table.blueTable tbody td {
    font-size: 13px;
  }

  table.blueTable tbody tr{
    height: 80px;
  }

  table.blueTable tr:nth-child(even) {
    background: #D0E4F5;
  }
  table.blueTable thead {
    background: #D0E4F5;
    background: -moz-linear-gradient(top, #dcebf7 0%, #d4e6f6 66%, #D0E4F5 100%);
    background: -webkit-linear-gradient(top, #dcebf7 0%, #d4e6f6 66%, #D0E4F5 100%);
    background: linear-gradient(to bottom, #dcebf7 0%, #d4e6f6 66%, #D0E4F5 100%);
    border-bottom: 0px solid #444444;
  }
  table.blueTable thead th {
    font-size: 15px;
    font-weight: bold;
    color: #000000;
    border-left: 1px solid #FFFFFF;
  }
  table.blueTable thead th:first-child {
    border-left: none;
  }

  table.blueTable tfoot td {
    font-size: 8px;
  }
  table.blueTable tfoot .links {
    text-align: right;
  }
  table.blueTable tfoot .links a{
    display: inline-block;
    background: #1C6EA4;
    color: #FFFFFF;
    padding: 2px 8px;
  }

  table.blueTable{
      page-break-after: always;
  }

  .container2 {
    margin: 0 auto;
    margin-top: 20px;
    width:300px;
    height:200px;

    background: white;
    box-shadow: 1px 1px 2px #fff inset,
                -1px -1px 2px #fff inset;
    border-radius: 3px/6px;         
      
      
  }

  html{
      font-family: "Trebuchet MS" ;
  }

  

  
  .shape{
      width: 200px;
      height: 200px;
      background-color: #00eaff;
      border-radius: 50%;
      border-top-right-radius: 0;
      transform: rotate(-45deg);
      float: left;
      margin-top: 15px;
      margin-left: 20px;
      border: 5px solid #3578BD;
      
  }
  .labelReport{
      transform: rotate(45deg);
      color:black;
  }


  @media (max-width:960px) { 
    .shapeKey{
      width : 50px ;
      height: 50px ;
    }

    .labelReport{
      padding-left: 5px !important ;
      padding-top: 5px !important;
    }

    .largeShapeLabel{
      display:none !important;
    }

    #reportTableHeader{
      width: 300px !important;
    }


  }

  @media (min-width:961px){
    .shapeKey{
      width : 80px ;
      height: 80px ;

    }



    .smallShapeLabel{
      display:none !important;
    }


  }