.location-profile-table {
	margin-bottom: 50px !important;
}
#location-profile-info-table {
	border-collapse: collapse;
}
#location-profile-info-table > tbody > tr > td {
	padding-top: 1em;
	padding-bottom: 1em;
}
.location-status-info-table > tbody > tr > td {
	padding-bottom: 25px !important;
	width: 50%;
}
#gallon-usage-table th,
#gallon-usage-table td {
	text-align: center;
	width: 10%;
}
#carbon-saved th,
#carbon-saved td {
	text-align: center;
	width: 33%;
}
.highlight-row:hover {
	background-color: lightyellow !important;
}
@media (max-width: 1325px) {
	.col-sm-4 {
		width: 100% !important;
	}
}
