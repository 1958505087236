@use '../../_base.scss';

.outerContainer {
	height: 100vh;
	width: 100vw;

	.formContainer {
		display: flex;
		align-items: center;
		justify-content: center;
		padding-top: 100px;

		.loginForm {
			width: 400px;
			background-color: #ebf2f8;
			display: flex;
			flex-direction: column;
			align-items: center;
			border: 2px solid #f1f1f1;
			border-radius: 25px;
			box-shadow: -1px 1px 10px 2px #5e5e5e;

			.loginImage {
				width: 75%;
				padding-top: 30px;
				padding-bottom: 50px;
			}

			.form-group {
				width: 75%;

				.loginInput {
					background-color: base.$theme-white !important;
				}
			}

			.captcha {
				padding-top: 25px;
				padding-bottom: 35px;
			}

			.loginButton {
				font-size: 21px;
				color: #ffffff;
				border: 0;
				background-color: #23799e !important;
				border-color: #23799e !important;
				border-radius: 3px;
				transition: all 250ms ease-in-out;
				width: 75%;
			}

			.forgotPassButton {
				font-size: 21px;
				width: 75%;
				color: #23799e !important;
			}
		}
	}

	.waves {
		position: fixed;
		bottom: 10px;
		width: 100%;
		height: 10vh;
		margin-bottom: -7px;
		min-height: 50px;
		max-height: 150px;
	}
	.waveFill {
		position: fixed;
		bottom: 0;
		height: 10px;
		width: 100%;
		background-color: white;
	}

	@media all and (max-height: 800px) {
		.formContainer {
			display: flex;
			align-items: center;
			justify-content: center;
			padding-top: 50px;
		}
	}
	@media all and (max-height: 700px) {
		.formContainer {
			display: flex;
			align-items: center;
			justify-content: center;
			padding-top: 25px;
		}
		.waves {
			display: none;
		}
		.waveFill {
			display: none;
		}
	}
	@media all and (max-height: 600px) {
		.formContainer {
			display: flex;
			align-items: center;
			justify-content: center;
			padding-top: 5px;
		}
		.waves {
			display: none;
		}
		.waveFill {
			display: none;
		}
	}
}

/* Animation */
.parallax > use {
	animation: move-forever 25s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
}
.parallax > use:nth-child(1) {
	animation-delay: -2s;
	animation-duration: 7s;
}
.parallax > use:nth-child(2) {
	animation-delay: -3s;
	animation-duration: 10s;
}
.parallax > use:nth-child(3) {
	animation-delay: -4s;
	animation-duration: 13s;
}
.parallax > use:nth-child(4) {
	animation-delay: -5s;
	animation-duration: 20s;
}
@keyframes move-forever {
	0% {
		transform: translate3d(-90px, 0, 0);
	}
	100% {
		transform: translate3d(85px, 0, 0);
	}
}
