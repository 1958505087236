.waterUseOuterContainer {
    width: 100vw;
    height: 100vh;
    margin: 0 auto;

    .waterUsePageWrap {
        height: calc(100vh - 70px);
        width: calc(100vw - 70px);
        margin: 0px 0px 0px 0px !important;

        .waterUse-top-row {
            height: 40%;
            width: calc(100vw - 100px);
            justify-content: center;
            align-items: center;
            margin-left: 70px !important;
        }
        .waterUse-bottom-row {
            height: 60%;
            width: calc(100vw - 100px);
            justify-content: center;
            align-items: center;
            margin-left: 70px !important;
        }
        .waterUse-used-top-row {
            height: 60%;
            width: auto;
        }
        .waterUse-used-bottom-row {
            height: 40%;
            width: auto;
            .flushesBlock {
                display: flex;
                height: 85%;
                padding-top: 10px;
            }
        }
        .waterUseGraphBlock {
            display: flex;
            height: 100%;
            padding-top: 10px;
        }
        .dashboardBlock {
            display: flex;
            height: 85%;
        }
        .testBlock {
            display: flex;
            height: 50%;
            padding-bottom: 5px;
        }
        .testBlock-Bottom {
            display: flex;
            height: 50%;
            padding-top: 5px;
        }
    }
}
