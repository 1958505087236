@use '../../_base.scss';

.reportOuterContainer {
    width: 100vw;
    height: 100vh;
    margin: 0 auto;
}

.reportPageWrap {
    height: calc(100vh - 70px);
    width: calc(100vw - 70px);
    margin: 0px 0px 0px 70px !important;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif !important;
}

.reportViewRow {
    width: calc(100vw - 140px);
    margin: 20px 20px 20px 20px !important;
    background-color: #ffffff;
    border-radius: 25px;
	box-shadow: base.$block-box-shadow;
}