.graphBackground {
    background-color: #fefefe;
    border-radius: 25px;
    box-shadow: 0px 1px 4px 0px rgb(0, 0, 0, 0.5);
    width: 100%;
    margin-bottom: 12px;
}

.graphLabel {
    margin-top: 5px;
    margin-left: 15px;
    margin-bottom: 5px;
    color: #BABFC9;
    font-size: 2vh;
}

.dataGraph {
    /* height: 90%; */
    height: 25vh;
    /* padding-top: 2%;
    padding-bottom: 2%; */
    padding-left: 2%;
    padding-right: 2%;
}

.expandedWaterUseGraph {
    height: 500px;
    width: 95vw;
}

.modal-100w {
    width: 100vw;
    max-width: 100vw !important;
}