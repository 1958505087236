@use '../../_base.scss';

.helpView {
    height: 90%;
    width: calc(100vw - 100px);
    justify-content: center;
    align-items: center;
    padding-top: 20px;
    margin-left: 15px !important;
    margin-top: 24px;
    background-color: #fefefe;
    border-radius: 25px;
    box-shadow: 0px 1px 4px 0px rgb(0, 0, 0, 0.5);
    overflow: auto;
}

.leftCol {
    /* height: 100%; */
    width: 100%;
    /* overflow: scroll; */
}

.rightCol {
    /* height: 100%; */
    width: 100%;
    /* overflow: scroll; */
}

.videoRow {
    width: auto;
}

.helpRow {
    padding-top: 12px;
    padding-bottom: 12px;
    /* height: 50%; */
}

.faqRow {
    height: 100%;
    width: 100%;
    margin-top: 24px;
    width: auto;
}

.topicsRow {
    height: 100%;
    width: 100%;
    margin-top: 24px;
    width: auto;
    /* height: 100%; */
}

@media screen and (max-width: 767px) {
    .helpView {
        height: 100vh;
        width: 100vw;
        justify-content: center;
        align-items: center;
        padding-top: 20px;
        margin: 0 !important;
        // background-color: #EBF2F8;
        border-radius: 0px;
        box-shadow: none;
        overflow: visible;
    }
}