@use '../../_base.scss';

.alertView-top-row {
	height: 40%;
    width: 90%;
	justify-content: center;
	align-items: center;
	padding-top: 12px;
}
.alertView-bottom-row {
	height: 60%;
    width: 90%;
	justify-content: center;
	align-items: center;
}
.alertView-table {
	width: calc(100vw - 140px);
	justify-content: center;
	align-items: center;
	margin-left: 90px !important;
	margin-top: 20px;
	margin-right: 20px;
    background-color: base.$bg-color;
	border-radius: 25px;
	box-shadow: base.$block-box-shadow;
}
.severity-5 {
	text-align: center;
}
.severity-4 {
	text-align: center;
}
.severity-3 {
	text-align: center;
}
.severity-2 {
	height: 100px;
	text-align: center;
}
.severity-1 {
	height: 100px;
	text-align: center;
}
.modal-75w {
	width: 75vw;
	max-width: 75vw !important;
}

@media screen and (max-width: 650px) {
	.alertView-top-row {
		width: 100vw;
	}
	.alertView-bottom-row {
		width: 100vw;
	}
}