.toolsOuterContainer {
    width: 100vw;
    height: 100vh;
    margin: 0 auto;
    .toolsPageWrap {
        height: calc(100vh - 70px);
        width: calc(100vw - 70px);
        margin: 0px 0px 0px 0px !important;
        overflow: auto;
        .tools-row {
            height: auto;
            width: auto;
            justify-content: center;
            align-items: center;
            padding-top: 20px;
            padding-bottom: 12px;
            margin-left: 70px !important;
        }
    }
}
