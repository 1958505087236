@use '../../base';

.menuLogo {
    position: absolute;
    top: 10px;
    width: 150px;
    height: 50px;
}

#page-wrap {
    text-align: center;
    overflow: auto;
}

/* Topbar */
.dashboardTopBar {
    height: 70px;
    width: 100vw;
    margin-right: 0px !important;
    margin-left: 0px !important;
    background-color: base.$bg-color;
    vertical-align: middle;
}

.logoClass {
    position: absolute;
    margin-left: 100px;
    padding-top: 20px;
    width: 150px;
    height: 50px;
}

.endMasqueradeColumn {
    float: right;
    margin-right: 30px;
    padding-top: 20px;
}

.dashboardTimeButtons {
    float: right;
    margin-right: 30px;
    padding-top: 20px;
}

.helpButton {
    float: right !important;
    margin-right: 30px;
    padding-top: 20px;
}
.calcButton {
    float: right !important;
    margin-right: 30px;
    padding-top: 20px;
}
.waterRateButton {
    float: right !important;
    margin-right: 30px;
    padding-top: 20px;
}

@media screen and (max-width: 767px) {
    .dashboardTopBar {
        height: 140px;
        width: 100vw;
        // padding-left: 60px;
    }

    .logoColumn {
        width: 100%;
        height: 70px;
        display: flex;
        justify-content: center;
        position: absolute;
        background-color: base.$bg-color;
        z-index: 99;
    }
    
    .logoClass {
        display: block;
        padding-top: 20px;
        margin-left: auto;
        margin-right: auto;
    }

    .timeButtonColumn {
        width: 100%;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .dashboardTimeButtons {
        float: none;
        margin: 0;
        padding-top: 70px;
    }

    .helpButton {
        float: right !important;
        margin-right: 30px;
        padding-top: 70px;
    }
    .calcButton {
        float: right !important;
        margin-right: 30px;
        padding-top: 70px;
    }
    .waterRateButton {
        float: right !important;
        margin-right: 30px;
        padding-top: 70px;
    }
}

@media screen and (max-width: 531px) {
    .dashboardTopBar {
        height: 160px;
        width: 100vw;
        // padding-left: 60px;
    }
}
// @media screen and (max-width: 650px) {
//     .dashboardTimeButtons {
//         margin-right: 5px;
//         margin-left: 5px;
//         margin-top: 70px;
//     }
// }

/* Sidebar Minimized */
.sidebarMin {
    position: absolute;
    background-color: base.$bg-color;
    height: 100vh !important;
    width: 70px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

/* Break points for responsive sidebar */

/* Portrait rules */
@media screen and (max-width: 375px) and (min-height: 740px) {
    .sidebarMin {
        height: 185vh;
        justify-content: start;
        padding-top: 28vh;
        width: 60px;
    }
}
@media screen and (max-width: 375px) and (min-height: 665px) and (max-height: 735px) {
    .sidebarMin {
        height: 210vh;
        justify-content: start;
        padding-top: 28vh;
        width: 60px;
    }
}
@media screen and (min-width: 376px) and (max-width: 415px) and (max-height: 737px) {
    .sidebarMin {
        height: 195vh;
        justify-content: start;
        padding-top: 28vh;
        width: 60px;
    }
}
@media screen and (min-width: 410px) and (max-width: 415px) and (min-height: 738px) and (max-height: 825px) {
    .sidebarMin {
        height: 180vh;
        justify-content: start;
        padding-top: 28vh;
        width: 60px;
    }
}
@media screen and (min-width: 310px) and (max-width: 322px) {
    .sidebarMin {
        height: 230vh;
        justify-content: start;
        padding-top: 28vh;
        width: 60px;
    }
}
@media screen and (min-width: 355px) and (max-width: 365px) and (min-height: 635px) and (max-height: 645px) {
    .sidebarMin {
        height: 210vh;
        justify-content: start;
        padding-top: 28vh;
        width: 60px;
    }
}

/* Landscape rules */
@media screen and (min-width: 769px) and (max-width: 900px) {
    .sidebarMin {
        height: 100vh;
        justify-content: space-evenly;
        padding-top: 12vh;

        .sidebarIcon {
            padding-bottom: 0 !important;
        }
        .logoutButton {
            display: block;
            position: absolute;
            bottom: 8px;
        }
    }
}
@media screen and (min-height: 376px) and (max-height: 415px) and (max-width: 737px) {
    .sidebarMin {
        height: 260vh;
        justify-content: flex-start;
        padding-top: 32vh;

        .sidebarIcon {
            padding: 10px !important;
        }
        .logoutButton {
            display: block;
            position: absolute;
            bottom: 8px;
        }
    }
}
@media screen and (min-height: 365px) and (max-height: 376px) and (min-width: 665px) and (max-width: 675px) {
    .sidebarMin {
        height: 280vh;
        justify-content: flex-start;
        padding-top: 35vh;

        .sidebarIcon {
            padding: 10px !important;
        }
        .logoutButton {
            display: block;
            position: absolute;
            bottom: 8px;
        }
    }
}
@media screen and (min-height: 355px) and (max-height: 361px) and (min-width: 635px) and (max-width: 642px) {
    .sidebarMin {
        height: 290vh;
        justify-content: flex-start;
        padding-top: 35vh;

        .sidebarIcon {
            padding: 10px !important;
        }
        .logoutButton {
            display: block;
            position: absolute;
            bottom: 8px;
        }
    }
}
@media screen and (min-height: 310px) and (max-height: 322px) {
    .sidebarMin {
        height: 320vh;
        justify-content: flex-start;
        padding-top: 35vh;

        .sidebarIcon {
            padding: 10px !important;
        }
        .logoutButton {
            display: block;
            position: absolute;
            bottom: 8px;
        }
    }
}

.sidebarIcon {
    display: block;
    padding-bottom: 50px;
}

.logoutButton {
    display: block;
    position: absolute;
    bottom: 50px;
}

/* Sidebar Menu */
.bm-item {
    display: block;
    text-decoration: none;
    color: base.$theme-ligt-blue;
    transition: color 0.2s;
}

.item-padding {
    padding-top: 25px;
    padding-bottom: 25px;
}

@media screen and (max-height: 740px) {
    .item-padding {
        padding-top: 15px;
        padding-bottom: 15px;
    }
}

@media screen and (max-height: 645px) {
    .item-padding {
        padding-top: 5px;
        padding-bottom: 5px;
    }
}

@media screen and (max-height: 540px) {
    .item-padding {
        padding-top: 1px;
        padding-bottom: 1px;
    }
}

.sidebar-item {
    display: flex;
    align-items: center;
    justify-content: left;
}

.selected {
    color: base.$theme-blue;
}

.menuLogout {
    position: absolute;
    bottom: 50px;
}

/* Change color on hover */
.bm-item:hover > .svg {
    color: base.$theme-blue;
    text-decoration: none;
}

.bm-menu a:focus > .svg {
    color: base.$theme-blue;
    text-decoration: none;
}

/* Position and sizing of burger button */
.bm-burger-button {
    position: fixed;
    width: 30px;
    height: 26px;
    left: 20px;
    top: 30px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
    background: base.$theme-blue;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
    height: 24px;
    width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
    background: base.$theme-ligt-blue;
}

/* General sidebar styles */
.bm-menu {
    background-color: base.$bg-color;
    padding: 2.5em 1.5em 0;
    font-size: 1.75em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
    fill: base.$bg-color;
}

/* Wrapper for item list */
.bm-item-list {
    color: base.$theme-ligt-blue;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

/* Styling of overlay */
.bm-overlay {
    background: rgba(0, 0, 0, 0.3);
}

div.sidebarTooltip {
    border: 1px solid base.$theme-ligt-blue;
    color: #212529;
}
