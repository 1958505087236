@use '../../_base.scss';

.profileViewRow {
    height: 90%;
    width: calc(100vw - 70px);
    justify-content: center;
    align-items: center;
}

.settingsCol {
    display: flex;
    height: 100%;
    padding-top: 20px;
}

.settingsBlock {
    width: 100%;
    height: 100%;
    background-color: #fefefe;
    border-radius: 25px;
    box-shadow: 0px 1px 4px 0px rgb(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.nav-tabs .nav-link.active {
    color: #3578bd !important;
}

.nav-tabs .nav-link {
    color: black;
}

.tab-content {
    width: 100%;
    height: 100%;
    overflow: auto;
}

.navbar-collapse.in {
    display: block !important;
}

@media screen and (max-width: 650px) {
    .profileViewRow {
        width: 100vw;
    }
    .settingsBlock {
        width: 100%;
        height: 100%;
        background-color: #fefefe;
        border-radius: 0px;
        box-shadow: none;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .tab-content {
        width: 100%;
        height: 100%;
        overflow: visible;
    }
}