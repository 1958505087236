.FAQView {
    padding: 20px !important;
    background-color: #ffffff !important;
    border-radius: 20px !important;
    box-shadow: 0px 1px 4px 0px rgb(0, 0, 0, 0.5) !important;
    color: black !important;
    min-width: 100% !important;
    max-width: 100% !important;
    word-wrap: normal !important;
    border-width: 0px !important;
}

.Dropdown{
    margin-bottom: 15px !important;

}