.dpw {
    height: calc(100vh - 70px);
    width: calc(100vw - 70px);
    margin: 0px 0px 0px 0px !important;
    padding-top: 20px;
}

.db-top-row {
    height: 90%;
    width: calc(100vw - 100px);
    justify-content: center;
    align-items: center;
    margin-left: 70px !important;
}

.overflowBox {
    overflow-y: auto;
    height: 100%;
}

.overflowBox p:after {
    width: 45%;
    text-align: center;
    content: ' ';
    display: block;
    margin: auto;
    border-bottom: 1px dotted #d0d0d0;
}

.PhoneInputInput {
    display: block;
    width: 100%;
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    appearance: none;
    border-radius: .375rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;

}

@media screen and (max-width: 768px) {


    .db-top-row {
        height: 90%;
        width: calc(100vw - 100px);
        justify-content: center;
        align-items: center;
        margin-left: 70px !important;
    }

    .overflowBox {
        height: 65%;
        overflow-y: auto;
    }

}