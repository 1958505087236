@use '../../../_base.scss';

.leakCostBackground {
    background-color: base.$bg-color;
    border-radius: 25px;
    box-shadow: base.$block-box-shadow;
}

.leakCostLeaked {
    text-align: center;
    margin: auto;
    justify-content: center;
    align-items: center;
    font-size: 6vh;
    color: #4cc0ac;
}
