.video-responsive {
    overflow: visible;
    padding-bottom: 56.25%; 
    position: relative;
    height: 0;
    border-radius: 10px;
    box-shadow: 0px 1px 4px 0px rgb(0, 0, 0, 0.5);
  }
  
  .video-responsive iframe {
    margin: 0px 0px 0px 0px;
    height: 100%;
    width: 100%;
    position: absolute;
    border-radius: 10px;
    box-shadow: 0px 1px 4px 0px rgb(0, 0, 0, 0.5);
  }

  .test {
    overflow: scroll;
  }