.update-var-input {
  width: 100px !important;
}
.table-update {
  width: 75% !important;
}
.table-update td {
  width: 100px !important;
  text-align: center;
}
